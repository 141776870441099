import React, {useState, useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {message, Button, Table, Tooltip} from 'antd';
import Filters from './Filters';
import {DELIVERY_TYPE} from '../../dictionary';
import Anchor from '../../Widgets/Anchor';
import Tag from '../../Widgets/Tag';
import Row from '../../Widgets/Row';
import StatusTag from './StatusTag';
import LogisticCsv from '../../Exports/LogisticCsv';
import {
  CloudUploadOutlined,
  EditOutlined,
  CopyOutlined,
  CloseCircleOutlined,
  PrinterOutlined,
  PlusOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import Modal from './Modals';
import {navigate} from 'gatsby';
const qs = require('query-string');

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function LogisticCenter({location}) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [filters, setFilters] = useState({
    search_str: '',
    logistics_type: 'all',
    logistics_status: 'all',
    is_item_manufacture: true,
    shipping_time_before_today: false,
    is_areas: false,
    ...PAGINATION_INIT,
  });
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState(null);
  const [selectedRowIDs, setSelectedRowIDs] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalParams, setModalParams] = useState({});

  const changeFilters = (obj) => setFilters((prev) => ({...prev, ...obj}));

  const getRecords = useCallback(async () => {
    let {
      current,
      pageSize,
      logistics_type,
      logistics_status,
      search_str,
      shipping_time_before_today,
      is_areas,
    } = filters;

    setLoading(true);

    let params = {
      offset: (current - 1) * pageSize,
      limit: pageSize,
      search: search_str,
      ...(logistics_type !== 'all' && {
        logistics_type,
      }),
      ...(logistics_status !== 'all' && {
        logistics_status,
      }),
      is_areas,
      is_item_manufacture: true,
      shipping_time: shipping_time_before_today
        ? `${new Date().getFullYear()}-${(
            '00' +
            (new Date().getMonth() + 1)
          ).slice(-2)}-${new Date().getDate()}`
        : '',
      order_voided: false,
    };

    try {
      const {results, count} = await actions.getLogistics(params);
      setRecords(results);
      setTotal(count);
    } catch (ex) {
      message.warning('取得物流單錯誤');
    }
    setLoading(false);
  }, [filters, actions]);

  const handleActionButtonOnClick = async (type, instance) => {
    try {
      setLoading(true);
      switch (type) {
        case 'dispatch':
          openModal('dispatch', instance);
          break;
        case 'dispatch_batch':
          const isAllDefaultStatus = selectedRows.every(
            (row) => row.logistics_status === 'default',
          );
          if (!isAllDefaultStatus) {
            message.warning('有物流單狀態不是「未處理」，請重新選擇。');
            setLoading(false);
            return;
          }
          openModal('dispatch_batch');
          break;
        case 'add':
          openModal('add');
          break;
        case 'edit':
          openModal('edit', instance);
          break;
        case 'copy':
          openModal('copy', instance);
          break;
        case 'delete':
          openModal('cancel', instance);
          break;
        case 'print':
          const {url} = await actions.printLogisticByID(instance.id);
          window.open(url, '_blank');
          message.success('物流單下載成功');
          break;
        case 'print_batch':
          const isAllTallyingStatus = selectedRows.every(
            (row) => row.logistics_status === 'tallying',
          );
          if (!isAllTallyingStatus) {
            message.warning('有物流單狀態不是「待出貨」，請重新選擇。');
            setLoading(false);
            return;
          }
          try {
            await actions.batchPrintLogistic({logistics_ids: selectedRowIDs});
            message.success(
              <span>
                物流單明細已進入下載佇列 <Anchor to={`/downloads`}>查看</Anchor>
              </span>,
            );
          } catch (ex) {
            message.warning('批次列印物流單失敗');
          }
          setLoading(false);
          break;
        default:
          throw new Error('未知的操作類型');
      }
    } catch (ex) {
      console.warn(ex);
      message.warning('操作失敗');
    }
    setLoading(false);
  };

  const openModal = (type, instance = {}) => {
    setModalType(type);
    setModalVisible(true);
    if (type === 'dispatch_batch') {
      setSelected(selectedRows);
    } else {
      setSelected(instance);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalType(null);
    setSelected(null);
    setSelectedRows([]);
    setSelectedRowIDs([]);
    clearUrlParams();
  };

  const getVisibleActions = (status) => {
    switch (status) {
      case 'default':
        return ['dispatch', 'edit', 'copy', 'cancel', 'print'];
      case 'tallying':
        return ['copy', 'cancel', 'print'];
      case 'pending':
        return ['cancel', 'copy', 'print'];
      case 'cancelled':
        return [];
      default:
        return ['copy', 'print'];
    }
  };

  const clearUrlParams = useCallback(() => {
    navigate(location.pathname, {replace: true});
    setModalParams({});
  }, [location.pathname]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  useEffect(() => {
    setSelected(null);
    setSelectedRows([]);
    setSelectedRowIDs([]);
  }, [filters]);

  useEffect(() => {
    const {
      order_id,
      type,
      logistic_id,
      store_type,
      st_code,
      rstore_name,
      rstore_addr,
    } = qs.parse(location.search);
    if (store_type) {
      setModalParams({
        order_id,
        type,
        logistic_id,
        store_type,
        st_code,
        rstore_name,
        rstore_addr,
      });

      if (type === 'add') {
        openModal('add');
      } else {
        if (!records.length) return;
        let instance = records.find(
          (record) => record.id === Number(logistic_id),
        );
        // 假設使用者不是在預設的前二十筆做編輯，則需要用編號去取得訂單
        if (!instance) {
          actions
            .getOrders({search: order_id})
            .then(({results}) => {
              if (!results.length) {
                message.warning('訂單不存在');
                return;
              } else {
                instance = results.find(
                  (result) => result.id === Number(logistic_id),
                );
              }
              openModal('edit', instance);
            })
            .catch((error) => {
              console.error('獲取訂單時出錯:', error);
            });
        } else {
          openModal('edit', instance);
        }
      }
    }
  }, [location.search, records]);

  const columns = useMemo(
    () => [
      {
        title: '編號',
        width: 80,
        render: (_, instance) => (
          <Row>
            <Anchor
              type="button"
              onClick={() => {
                openModal('edit', instance);
              }}>{`#${instance.id}`}</Anchor>
            {instance.dispatch_status === 'failed' &&
              instance.dispatches.length > 0 && (
                <Tooltip
                  title={instance.dispatches[0].error_message}
                  arrowPointAtCenter>
                  <ExclamationOutlined
                    style={{color: 'red', fontSize: 16, fontWeight: 'bold'}}
                  />
                </Tooltip>
              )}
          </Row>
        ),
      },
      {
        title: '狀態',
        width: 120,
        render: (_, instance) => <StatusTag instance={instance} />,
      },
      {
        title: '訂單編號',
        render: (_, instance) => (
          <Row>
            <Anchor to={`/order/?id=${instance.order}`}>
              {instance.display_id}
            </Anchor>
            <CopyOutlined
              style={{marginLeft: 8, cursor: 'pointer'}}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(instance.display_id);
                message.success('訂單編號已複製到剪貼簿');
              }}
            />
          </Row>
        ),
      },
      {
        title: '顧客姓名',
        render: (_, instance) => (
          <Anchor to={`/member/?id=${instance.buyer_id}`}>
            {instance.receiver_name || instance.buyer_name || '-'}
          </Anchor>
        ),
      },
      {
        title: '運送方式',
        render: (_, instance) => (
          <Row>
            <div>{DELIVERY_TYPE[instance.logistics_type]}</div>
            {instance.is_delivery_private &&
            ['hct', 'sf', 'mailing'].includes(instance.logistics_type) ? (
              <Tag shape="circle" color="#d0543c" style={{marginLeft: 5}}>
                保
              </Tag>
            ) : null}
          </Row>
        ),
      },
      {
        title: '收件地址',
        render: (_, instance) => {
          return (
            <Row>
              {['xdelivery', 'payuni'].includes(instance?.logistics_type) ? (
                instance?.store_name
              ) : (
                <>
                  {[
                    instance?.receiver_city,
                    instance?.receiver_district,
                    instance?.receiver_address,
                  ]
                    .filter(Boolean)
                    .join('')}
                  {instance?.is_areas && (
                    <span style={{color: '#EEA23E', marginLeft: '5px'}}>
                      聯運
                    </span>
                  )}
                </>
              )}
            </Row>
          );
        },
      },
      {
        title: '追蹤單號',
        render: (_, instance) => (
          <div>
            {(instance.tracking_number && instance.tracking_number) || '---'}
          </div>
        ),
      },
      {
        title: '出貨時間',
        render: (_, instance) =>
          instance.shipping_time ? (
            <div> {instance.shipping_time.slice(0, 10)}</div>
          ) : (
            <div> --- </div>
          ),
      },
      {
        title: '操作',
        width: 160,
        fixed: 'right',
        render: (_, instance) => {
          const visibleActions = getVisibleActions(instance.logistics_status);
          return (
            <Row>
              {visibleActions.includes('dispatch') && (
                <ActionButton
                  onClick={() =>
                    handleActionButtonOnClick('dispatch', instance)
                  }>
                  <CloudUploadOutlined style={{color: '#227FE9'}} />
                </ActionButton>
              )}
              {visibleActions.includes('edit') && (
                <ActionButton
                  onClick={() => handleActionButtonOnClick('edit', instance)}>
                  <EditOutlined />
                </ActionButton>
              )}
              {visibleActions.includes('copy') && (
                <ActionButton
                  onClick={() => handleActionButtonOnClick('copy', instance)}>
                  <CopyOutlined />
                </ActionButton>
              )}
              {visibleActions.includes('cancel') && (
                <ActionButton
                  onClick={() => handleActionButtonOnClick('delete', instance)}>
                  <CloseCircleOutlined />
                </ActionButton>
              )}
              {visibleActions.includes('print') && (
                <ActionButton
                  onClick={() => handleActionButtonOnClick('print', instance)}>
                  <PrinterOutlined />
                </ActionButton>
              )}
            </Row>
          );
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Filters
        filters={filters}
        changeFilters={changeFilters}
        getRecords={getRecords}
      />
      <Container>
        <Actions>
          <StyledButton
            dispatch="true"
            disabled={!selectedRowIDs.length}
            onClick={() => handleActionButtonOnClick('dispatch_batch')}
            icon={
              <CloudUploadOutlined
                style={{
                  fontSize: '20px',
                  color: 'white',
                }}
              />
            }>
            拋單
          </StyledButton>
          <StyledButton
            disabled={!selectedRowIDs.length}
            onClick={() => handleActionButtonOnClick('print_batch')}
            icon={
              <PrinterOutlined
                style={{
                  fontSize: '20px',
                  color: '#227fe9',
                }}
              />
            }>
            列印
          </StyledButton>
          <LogisticCsv
            color="#227FE9"
            backgroundColor="#fff"
            style={{
              width: '120px',
              height: '40px',
              borderRadius: '4px',
              padding: '0 10px',
              fontSize: '14px',
              border: '1px solid #227FE9',
            }}>
            匯出出貨單
          </LogisticCsv>
        </Actions>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRowIDs,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowIDs(selectedRowKeys);
              setSelectedRows(selectedRows);
            },
          }}
          loading={loading}
          columns={columns}
          dataSource={records}
          rowKey={(item) => item.id}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            changeFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
          scroll={{
            x: 1600,
          }}
        />
      </Container>
      <FixedButton
        onClick={() => handleActionButtonOnClick('add')}
        icon={<PlusOutlined style={{color: '#fff', fontSize: '20px'}} />}
      />
      <Modal
        visible={modalVisible}
        type={modalType}
        record={selected}
        params={modalParams}
        destroyOnClose
        reset={clearUrlParams}
        onClose={closeModal}
        onUpdate={getRecords}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  && .ant-pagination {
    margin-bottom: 0;
  }
`;

const FixedButton = styled(Button)`
  &&& {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    display: inline-flex;
    height: 56px;
    width: 56px;
    background-color: #227fe9;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.12);
  }
`;

const ActionButton = styled.div`
  cursor: pointer;
  margin-right: 8px;
  .anticon {
    font-size: 20px;
    color: #6b7280;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 16px;
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 40px;
    background-color: ${(props) => (props.dispatch ? '#227FE9' : '#fff')};
    border: 1px solid ${(props) => (props.dispatch ? '#227FE9' : '#227FE9')};
    border-radius: 4px;
    padding: 0 16px;
    font-size: 14px;
    color: ${(props) => (props.dispatch ? '#fff' : '#227FE9')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    transition: background-color 0.3s ease;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;
